:root {
  --danger-color: #FF633F;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-xxl: 40px;
  --form-spacing: 30px;
  --input-button-color: #4D7CFE;
  --input-button-height: 36px;
  --input-botton-hover: 0.7;
  --input-border: none;
  --input-border-radius: 4px;
  --input-box-shadow: none;
  --input-box-sizing: border-box;
  --input-field-color: #EEF4FA;
  --input-field-height: 40px;
  --input-field-padding: 4%;
  --input-field-text-color: #778699;
  --screen-min-height: 300px;
  --title-opacity: 0.07;
  --title-font-size: clamp(12px, 10vw, 150px);
  --logo-height: 186px;
  --logo-width: 75px;
}

html, body {
  font-family: 'Nunito', 'Open Sans', 'Helvetica', sans-serif;
  height: 100%;
}

main {
  background: white;
  display: grid;
  grid-template-areas:"left right";
  grid-template-columns: 40% 60%;
  height: 100%;
  min-height: var(--screen-min-height);
}

.left {
  border-radius: 10px;
  box-shadow: 0 0 64px 0 rgba(74, 80, 91, 0.2);
  display: grid;
  grid-area: left;
  grid-template-areas: "logo" "form";
  grid-template-rows: 40% 60%;
}

.left__logo {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: logo;
  justify-content: flex-end;
}

.left__title {
  color: var(--text-color);
  display: none;
  font-style: normal;
  font-weight: bolder;
  font-size: var(--font-size-xxl);
  margin: auto;
  opacity: var(--title-opacity);
}

.left__form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: var(--form-spacing);
}

.left__img  {
  /* Load image from css instead of using CopyWebpackPlugin to copy image
    see https://stackoverflow.com/questions/48704770/webpack-is-not-copying-images-to-dist-folder
  */
  content: url("nx-logo.svg");
  width: var(--logo-height);
  height: var(--logo-width);
}

aside {
  align-items: center;
  display: flex;
  grid-area: right;
  justify-content: center;
}

aside .title {
  color: var(--text-color);
  font-style: normal;
  font-weight: bolder;
  font-size: var(--title-font-size);
  line-height: 100%;
  opacity: var(--title-opacity);
  width: 70%;
  user-select: none;
}

@media only screen and (max-width: 600px), only screen and (max-device-width: 600px) {
  main {
    grid-template-rows: 100%;
    grid-template-columns: 100%;
  }

  .left {
    box-shadow: none;
  }

  .left__title {
    display: block;
  }

  aside {
    display: none;
  }
}

.login-form {
  font-size: var(--font-size-xs);
  font-style: normal;
  width: 50%;
}

.login-form--mfa {
  display: none;
}

.login-form__field {
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  box-shadow: var(--input-box-shadow);
  box-sizing: var(--input-box-sizing);
}

input.login-form__field {
  background-color: var(--input-field-color);
  color: var(--input-field-text-color);
  height: var(--input-field-height);
  padding: var(--input-field-padding);
  width: 100%;
}

a.login-form__field {
  align-items: center;
  color: var(--input-field-text-color);
  display: flex;
  height: var(--input-field-height);
  justify-content: center;
}

a.login-form__field:hover {
  cursor: pointer;
  text-decoration: underline;
}

.login-form__caption {
  color: var(--input-field-text-color);
  padding-bottom: var(--input-field-padding);
  text-align: center;
}

.login-form input.login-form__field:focus {
  outline: none;
  box-shadow: none;
}

button.login-form__field {
  background-color: var(--input-button-color);
  color: white;
  font-size: var(--font-size-s);
  height: var(--input-button-height);
  position: relative;
  transition: color .3s ease, background-color .3s ease;
  width: 100%;
}

button.login-form__field:hover {
  color: white;
  opacity: var(--input-botton-hover);
}

#error-message, #mfa-error-message, #reset-password-error-message, #change-password-error-message, #activate-account-error-message {
  color: var(--danger-color);
  font-size: var(--font-size-xs);
  height: var(--input-field-height);
  text-align: center;
}

@-webkit-keyframes autofill {
  to {
    color: #fff;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.change_browser_popup {
  background-color: rgba(0,0,0,0.7);
  display: none;
  height: 100%;
  position: fixed;
  width: 100%;
}

.change_browser_popup p {
  color: white;
  font-size: 32px;
  position: relative;
  text-align: center;
  top: 50%;
}

#login_submit_spinner, #mfa-submit__spinner, #reset-password_submit_spinner, #activate-account_submit_spinner, #change-password_submit_spinner {
  transition: 0.3s linear;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.login-form--reset-password, .login-form--activate-account  {
  display: none;
}

button.login-form--reset-password__field, button.login-form--activate-account__field {
  background-color: white;
  border: none;
  color: var(--input-field-text-color);
  font-size: var(--font-size-s);
  height: var(--input-button-height);
  position: relative;
  width: 100%;
}

button.login-form--reset-password__field:hover, button.login-form--activate-account__field:hover {
  opacity: var(--input-botton-hover);
}

.login-form--change-password,
#activate-account,
#password-verification-caption, 
#self-activation-caption,
#reset-change-password-caption,
#self-activation-change-password-caption {
  display: none;
}

